import { render, staticRenderFns } from "./links.vue?vue&type=template&id=37e76483&scoped=true&"
import script from "./links.vue?vue&type=script&lang=js&"
export * from "./links.vue?vue&type=script&lang=js&"
import style0 from "./links.vue?vue&type=style&index=0&id=37e76483&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e76483",
  null
  
)

export default component.exports